<template>
  <div class="precision-step">
    <v-form @input="$emit('onValid', $event)">
      <div class="precision-step__field">
        <v-text-field
            :label="$vuetify.lang.t('$vuetify.step.precision.name.label') + ' *'"
            :placeholder="$vuetify.lang.t('$vuetify.step.precision.name.placeholder')"
            filled
            rounded
            :rules="[this.rulesRequired, rulesMinim(4)]"
            required
            @input="updateValue('name', $event)"
        ></v-text-field>
      </div>

      <div class="precision-step__field">
        <v-text-field
            :label="$vuetify.lang.t('$vuetify.step.precision.location.label') + ' *'"
            :placeholder="$vuetify.lang.t('$vuetify.step.precision.location.placeholder')"
            filled
            rounded
            append-icon="mdi-map-marker"
            :rules="[this.rulesRequired, rulesMinim(4)]"
            @input="updateValue('location', $event)"
        ></v-text-field>
      </div>

      <div class="precision-step__field">
        <v-text-field
            type="number"
            :label="$vuetify.lang.t('$vuetify.step.precision.duration.label') + ' *'"
            :placeholder="$vuetify.lang.t('$vuetify.step.precision.duration.placeholder')"
            filled
            rounded
            min="1"
            :rules="[this.rulesRequired]"
            @input="updateValue('duration', $event)"
        ></v-text-field>
      </div>

      <div class="precision-step__field">
        <v-text-field
            type="number"
            :label="$vuetify.lang.t('$vuetify.step.precision.personsCount.label') + ' *'"
            :placeholder="$vuetify.lang.t('$vuetify.step.precision.personsCount.placeholder')"
            filled
            rounded
            min="1"
            :rules="[this.rulesRequired]"
            @input="updateValue('personsCount', $event)"
        ></v-text-field>
      </div>
    </v-form>
  </div>
</template>

<script>
import rules from "@/mixins/rules";

export default {
  name: "PrecisionStep",
  props: ['value'],
  mixins: [rules],
  methods: {
    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    updateValue(name, $event) {
      const precision = {...this.value}
      if (name === 'name' || name === 'location') {
        precision[name] = $event;
      } else if ((name === 'duration' || name === 'personsCount') && this.isNumeric($event)) {
        precision[name] = $event;
      } else {
        precision[name] = null;
      }
      this.$emit('input', precision)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~vuetify/src/styles/settings/_variables';

.precision-step {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    flex-direction: column;

    &__field {
      width: 300px;
    }
  }


}
</style>
