<template>
  <div>
    <h1 class="welcome-title font-weight-dark">{{ $vuetify.lang.t('$vuetify.welcome.title1') }}</h1>
    <h2 class="welcome-subtitle font-weight-light">{{ $vuetify.lang.t('$vuetify.welcome.title2.no_bold') }} <span class="font-weight-regular">{{ $vuetify.lang.t('$vuetify.welcome.title2.bold') }}</span></h2>
    <div class="welcome-paragraph">
      <p class="welcome-paragraph__first ">{{ $vuetify.lang.t('$vuetify.welcome.paraf1') }}
      </p>
      <p class="welcome-paragraph__second font-weight-dark">{{ $vuetify.lang.t('$vuetify.welcome.paraf2') }}
      </p>
      <p class="welcome-paragraph__third">{{ $vuetify.lang.t('$vuetify.welcome.paraf3') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomeDescription"
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
@import "@/assets/design/variable.scss";

.welcome-title {
    font-size: 50px;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 30px;
  }
}

.welcome-subtitle {
  color: $primary;
  font-size: 60px;


  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 35px;
  }
}

.welcome-paragraph {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;

  &__first, &__second, &__third {
    font-size: 15px;
    width: 300px;


    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      width: auto;
    }
  }
}

</style>
