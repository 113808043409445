<template>
  <v-footer padless color="primary">
    <v-col
        class="text-center name-app"
        cols="12"
    >
      <strong class="white--text">Generator Review</strong>
    </v-col>
    <v-col
        class="text-center white--text credit"
        cols="12"
    >
   By Fabien Joalland & Jérémy Prat
    </v-col>
    <v-col
        class="text-center social-network-icons"
        cols="12"
    >
      <v-card-text class="card">
        <v-btn
            v-for="item in items"
            :key="item.icon"
            class="mx-0 white--text"
            :aria-label="item.ariaLabel"
            :href="item.href"
            icon
        >
          <v-icon size="24px">
            {{ item.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>
    </v-col>

  </v-footer>
</template>

<script>
export default {

  name: "FooterMain",
  data: () => ({
    items: [
      {icon: 'mdi-linkedin', ariaLabel: "Linkedin", href: "https://fr.linkedin.com/in/fjoalland"},
      {icon: 'mdi-instagram', ariaLabel: "Instagram", href: "https://www.instagram.com/jeremyprat_/?hl=en"}
    ],
  }),
}
</script>

<style scoped lang="scss">

.social-network-icons {
  padding-bottom: 0;
  padding-top: 0;

  .card {
    padding-top: 7px;
  }
}

.name-app {
  padding-top: 20px;
  padding-bottom: 7px;
}

.credit {
  font-size: 13px;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
