<template>
  <div class="avatar-select-container">
    <div class="avatar-select"
         :class="{ 'is-selected' : isSelected, 'mouse-leave': hoverOutAvatar}"
         @click="$emit('change', value)"
         @mouseleave="hoverOutAvatar = true">
      <img :src="require(`@/assets/avatars/${name}.svg`)" :alt="alt">
    </div>
    <div>
      <slot></slot>
    </div>
  </div>

</template>

<script>
export default {
  name: "AvatarSelect",
  props: {
    isSelected: {
      type: Boolean
    },
    name: {
      type: String
    },
    value: {
      type: String
    },
    alt: {
      type: String
    }
  },
  data() {
    return {
      hoverOutAvatar: false
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/design/variable.scss";
@import '~vuetify/src/styles/settings/_variables';

$padding: 0;

.avatar-select-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.avatar-select {
  display: flex;
  flex-direction: column;
  gap: 17px;

  height: 185px;
  width: 185px;

  padding: $padding;

  border-radius: 50%;
  border: 5px solid transparent;

  text-align: center;


  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: 135px;
    width: 135px;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: 120px;
    width: 120px;
  }

  &.mouse-leave:not(.is-selected) {
    animation-name: borderAnimationOut;
    animation-duration: 1s;
  }

  &:hover {
    cursor: pointer;

    &:not(.is-selected) {
      animation-name: borderAnimationIn;
      animation-duration: 0.5s;
      border: 5px solid $primary;
    }
  }

  &.is-selected {
    border: 5px solid $secondary;
    padding: $padding;
  }
}

@keyframes borderAnimationIn {
  from {
    padding: $padding;
    border: 5px solid rgba(211, 205, 45, 0);
  }
  to {
    padding: $padding;
    border: 5px solid $primary;
  }
}

@keyframes borderAnimationOut {
  from {
    padding: $padding;
    border: 5px solid $primary;
  }
  to {
    padding: $padding;
    border: 5px solid rgba(211, 205, 45, 0);
  }
}
</style>