<template>
  <v-navigation-drawer
      fixed
      bottom
      right
      temporary
      primary
      v-model="propModel"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          Generator Review
        </v-list-item-title>
        <v-list-item-subtitle>
          Générateur
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list
        dense
        nav
    >
      <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :disabled="item.disabled"
          :to="item.link"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

</template>

<script>
export default {
  name: "NavigationDrawer",
  props: {
    value: {
      type: Boolean
    }
  },
  computed: {
    propModel: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },
  },
  data() {
    return {
      drawer: true,
      items: [
        { title: this.$vuetify.lang.t('$vuetify.links.home'), icon: 'mdi-home', disabled: false, link: '/' },
        { title: this.$vuetify.lang.t('$vuetify.links.airbnb'), icon: 'mdi-home', disabled: false, link: '/airbnb-review' },
        { title: this.$vuetify.lang.t('$vuetify.links.contact'), icon: 'mdi-help-box', disabled: false, link: '/contact'  },
      ],
    }
  }
}
</script>

<style scoped>

</style>
