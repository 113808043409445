<template>
  <div class="key-words-step">
    <v-form @input="$emit('onValid', $event)">
    <div class="key-words-step__field">
      <p class="font-weight-light">{{ $vuetify.lang.t('$vuetify.step.keyWords.label') }}</p>
      <v-textarea
          filled
          rounded
          ref="textarea"
          name="input-7-1"
          v-model="textareaModel"
          :counter="maximumCharacters"
          :rules="rules"
          :label="$vuetify.lang.t('$vuetify.step.keyWords.label-textarea')+ ' *'"
          :placeholder="$vuetify.lang.t('$vuetify.step.keyWords.value')"
          :hint="$vuetify.lang.t('$vuetify.step.keyWords.hint')"
      ></v-textarea>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "KeyWordsStep",
  props: ['value'],
  data() {
    return {
      textareaModel: '',
      maximumCharacters: 60,
      rules: [
        value => !!value || this.$vuetify.lang.t('$vuetify.step.keyWords.required-message'),
        value => (value || '').length >= 4 || this.$vuetify.lang.t('$vuetify.step.keyWords.minimum-message'),
        value => (value || '').length < this.maximumCharacters || this.$vuetify.lang.t('$vuetify.step.keyWords.maximum-message'),
        value => {
          const occurrences = (value.match(/,/g) || []).length
          if (occurrences >= 1) {
            return true
          } else {
            return  this.$vuetify.lang.t('$vuetify.step.keyWords.minimum-keywords-message')
          }
        },
      ],
    }
  },
  methods: {
  },
  watch: {
    textareaModel() {
      if (this.$refs.textarea.valid) {
        this.$emit('input', this.textareaModel)
      } else {
        this.$emit('input', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/design/variable.scss";

.key-words-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  > * {
    width: 80%;
  }

  &__field {
  }

  &__chips {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
}

.autocomplete--adding {
  cursor: pointer;
}
</style>
