export default {
    methods: {
        async axiosReCaptcha(actioName) {
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded()

            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha(actioName)

            return `?g-recaptcha-response=${token}`;
        },
    },
};
