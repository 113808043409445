<template>
  <div>
    <div class="welcome-container">
      <ContactDescription class="welcome-container__description"/>
      <div class="welcome-container__background" role="img" :aria-label="$vuetify.lang.t('$vuetify.background.alt')"/>
    </div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="stepper-box" ref="stepperBox">
            <v-row justify="center">
              <v-col cols="11">
                <div v-if="messageSent" class="mt-5 text-center">{{$vuetify.lang.t('$vuetify.contact.messageSent')}}
                </div>
                <v-form ref="form" v-model="isValid" class="full">
                  <v-text-field :label="$vuetify.lang.t('$vuetify.contact.name')" v-model="form.name" required
                                :rules="[rulesRequired,rulesMinim(3)]" :disabled="fieldDisabled"/>
                  <v-text-field :label="$vuetify.lang.t('$vuetify.contact.firstName')" v-model="form.firstName" required
                                :rules="[rulesRequired,rulesMinim(3)]" :disabled="fieldDisabled"/>
                  <v-text-field :label="$vuetify.lang.t('$vuetify.contact.email')" v-model="form.email" type="email"
                                required :rules="[rulesRequired, rulesEmail]" :disabled="fieldDisabled"/>
                  <v-textarea :label="$vuetify.lang.t('$vuetify.contact.message')" v-model="form.message" required
                              :counter="255" :rules="[rulesRequired, rulesMinim(counter), rulesMax(255)]" :disabled="fieldDisabled"/>

                  <v-row no-gutters justify="end" class="mt-10">
                    <v-col cols="auto">
                      <button-widget @click.native="submitForm()" type="button" :disabled="!isValid || messageSent || inPending">
                        {{ $vuetify.lang.t('$vuetify.contact.btn') }}
                      </button-widget>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>

import animations from "@/mixins/animations";
import rules from "@/mixins/rules";
import ButtonWidget from "@/components/ButtonWidget";
import ContactService from "@/services/contact";
import axiosRecaptcha from "@/mixins/axios-recaptcha";
import ContactDescription from "@/components/ContactDescription";

export default {
  components: {ContactDescription, ButtonWidget},
  mixins: [animations, rules, axiosRecaptcha],
  metaInfo: {
    titleTemplate: '%s - Contact!',
  },
  data() {
    return {
      smAndDown: false,
      counter: 25,
      form: {
        name: '',
        firstName: '',
        email: '',
        message: ''
      },
      isValid: false,
      messageSent: false,
      inPending: false,
    }
  },
  computed: {
    fieldDisabled() {
      return this.messageSent || this.inPending;
    }
  },
  methods: {
    async submitForm() {
      try {
        this.inPending = true;
        const captchaQuery = await this.axiosReCaptcha('contact')
        await ContactService.postContact(this.form, captchaQuery);
        this.messageSent = true;
        this.inPending = false;
      } catch (e) {
        this.messageSent = false;
        this.inPending = false;
      }
    },
  },
  mounted() {
    this.smAndDown = this.$vuetify.breakpoint.smAndDown;
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.welcome-container__background {
  background-image: url("@/assets/img/image_contact.jpg");
  transform: scale(1);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    background-image: url("@/assets/img/image_airbnb_phone.jpg");
  }
}

</style>
