<template>

    <v-menu
        :key="'language'"
        :rounded="true"
        offset-y
    >
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom :disabled="smAndDown">
          <template v-slot:activator="{ on: tooltip }">
            <v-app-bar-nav-icon @click="$emit('navigation-drawer', true)" :aria-label="$vuetify.lang.t('$vuetify.select.langage')" v-bind="attrs" v-on="{ ...tooltip, ...menu }">
              <country-flag :country='currentLanguage' :rounded="true"/>
            </v-app-bar-nav-icon>

          </template>
          <span>Sélectionner un langage</span>
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item
            v-for="item in items"
            :key="item.lang"
            link
            @click="changeLanguage(item.lang)"
        >
          <country-flag :country='item.icon' :rounded="true"/>
        </v-list-item>
      </v-list>
    </v-menu>

</template>

<script>
import CountryFlag from 'vue-country-flag'

export default {
  name: "SelectLanguage",
  components: {
    CountryFlag
  },
  data: () => ({
    items: [{icon: 'fr', lang: 'fr'}, {icon: 'gb', lang: 'en'}, {icon: 'es', lang: 'es'}],
    currentLanguage: localStorage.getItem('currentLanguage'),
    smAndDown: false,
  }),
  created() {
    this.items = this.items.filter((item) => item.lang !== this.currentLanguage)
    this.currentLanguage = this.currentLanguage === 'en' ? 'gb' : this.currentLanguage;
    this.smAndDown =  this.$vuetify.breakpoint.smAndDown;
  },
  methods: {
    changeLanguage(lang) {
      localStorage.setItem('currentLanguage', lang);
      location.reload();
    }
  }
}
</script>

<style>

.normal-flag {
  margin: 0 !important;
}

</style>
