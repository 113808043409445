<template>
  <div>
    <h1 class="contact-title font-weight-dark">Contactez-nous!</h1>
    <h2 class="contact-subtitle font-weight-light">Nous sommes là pour vous aider</h2>
    <div class="contact-paragraph">
      <p class="contact-paragraph__first ">Si vous avez des questions ou des commentaires, n'hésitez pas à utiliser le formulaire ci-dessous pour nous les faire parvenir.
      </p>
      <p class="contact-paragraph__second font-weight-dark">Nous répondrons dans les plus brefs délais.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactDescription"
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
@import "@/assets/design/variable.scss";

.contact-title {
    font-size: 50px;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 30px;
  }
}

.contact-subtitle {
  color: $primary;
  font-size: 60px;


  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 35px;
  }
}

.contact-paragraph {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;

  &__first, &__second, &__third {
    font-size: 15px;
    width: 300px;


    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      width: auto;
    }
  }
}

</style>
