<template>
  <v-row no-gutters>
    <v-col>
      <avatar-select name="appartement"
                     value="appartement"
                     :is-selected="value === 'appartement'"
                     :alt="$vuetify.lang.t('$vuetify.step.houseType.appartment.alt')"
                     @change="$emit('input', $event)">{{ $vuetify.lang.t('$vuetify.step.houseType.appartement.label') }}
      </avatar-select>
    </v-col>
    <v-col>
      <avatar-select name="maison"
                     value="house"
                     :is-selected="value === 'house'"
                     :alt="$vuetify.lang.t('$vuetify.step.houseType.house.alt')"
                     @change="$emit('input', $event)">{{ $vuetify.lang.t('$vuetify.step.houseType.house.label') }}
      </avatar-select>
    </v-col>
    <v-col>
      <avatar-select name="bateau"
                     value="unusual"
                     :is-selected="value === 'unusual'"
                     :alt="$vuetify.lang.t('$vuetify.step.houseType.unusual.alt')"
                     @change="$emit('input', $event)">{{ $vuetify.lang.t('$vuetify.step.houseType.unusual.label') }}
      </avatar-select>
    </v-col>
  </v-row>
</template>

<script>
import AvatarSelect from "@/components/AvatarSelect";

export default {
  name: "PickHouseTypeStep",
  props: ['value'],
  components: {AvatarSelect}
}
</script>

<style scoped lang="scss">
.pick-character {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

</style>
