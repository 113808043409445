export default {
    methods: {
        handleScroll() {
            const scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;

            if (scrollTop < 400) {
                this.$refs.stepperBox?.style.setProperty('margin-top', `calc(${this.smAndDown ? 100 : 100}vh - ( ${scrollTop / 2}px)`);
            } else {
                this.$refs.stepperBox?.style.setProperty('margin-top', `calc(${this.smAndDown ? 100 : 100}vh - (200px)`);
            }
        },
    },
};
