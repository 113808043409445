<template>
  <div class="home-container">

    <div class="home-background" role="img" :aria-label="$vuetify.lang.t('$vuetify.background.alt')">
    </div>

    <div class="home-text--fixed">
      <h1 class="color-primary">Generator Review</h1>
      <h2>{{ $vuetify.lang.t('$vuetify.home.intro.h2') }} </h2>
      <h3>{{ $vuetify.lang.t('$vuetify.home.intro.h3') }} </h3>
      <p>{{ $vuetify.lang.t('$vuetify.home.intro.paragraph') }}</p>
    </div>

    <div class="home-container__product-container" ref="stepperBox">
      <div class="home-container__product-container__product">
        <card-product type="airbnb"></card-product>
        <router-link to="airbnb-review">
          <button-widget>Let's go</button-widget>
        </router-link>
      </div>
      <div class="home-container__product-container__product">
        <card-product type="hotel"></card-product>
        <button-widget disabled>Let's go</button-widget>
      </div>
      <div class="home-container__product-container__product">
        <card-product type="restaurant"></card-product>
        <button-widget disabled>Let's go</button-widget>
      </div>
      <div class="home-container__product-container__product">
        <card-product type="online-product"></card-product>
        <button-widget disabled>Let's go</button-widget>
      </div>
    </div>

    <!--    <div class="home-container&#45;&#45;step">-->
    <!--      <img class="perso" src="@/assets/img/home/perso_1.svg"/>-->
    <!--    </div>-->
    <!--    <div class="home-container&#45;&#45;step">-->
    <!--      <img class="perso" src="@/assets/img/home/perso_1.svg"/>-->
    <!--    </div>-->
    <!--    <div class="home-container&#45;&#45;step">-->
    <!--      <img class="perso" src="@/assets/img/home/perso_1.svg"/>-->
    <!--    </div>-->
  </div>

</template>

<script>
// @ is an alias to /src

import CardProduct from "@/components/CardProduct";
import ButtonWidget from "@/components/ButtonWidget";
import animations from "@/mixins/animations";

export default {
  name: 'HomeView',
  mixins: [animations],
  components: {ButtonWidget, CardProduct},
  mounted() {
  },
  destroyed() {
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/design/variable.scss";
@import '~vuetify/src/styles/settings/_variables';

.home-container {
  display: flex;
  align-items: end;
  justify-content: center;
  height: calc(100vh - 129px);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: 1262px;
  }

  &__product-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 50px;
    z-index: 3;

    &__product {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
    }
  }

  &--step {
    position: relative;
    width: 100%;
    height: 100vh;
  }
}

.home-text--fixed {
  position: absolute;
  left: 50px;
  top: 116px;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    left: 25px;
    max-width: calc(100% - 50px)
  }

  h1 {
    color: $tertiary;
    font-weight: lighter;
    font-size: 50px;
  }

  h2 {
    font-weight: bold;
    font-size: 40px;
  }

  h3 {
    font-weight: bold;
    font-size: 35px;
    line-height: 20px;


    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      line-height: 35px;
    }
  }

  p {
    margin-top: 15px;
    max-width: 600px;
  }
}

.home-background {
  position: absolute;
  background-position: bottom;
  background-size: cover;
  width: 100%;
  height: 100vh;
  z-index: 0;
  background-image: url("@/assets/img/home/accueil_fond.svg");


  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    background-position: top;
    top: 0;
  }
}

.perso {
  position: absolute;
  height: 1200px;
  right: 400px;
  bottom: -117px;
  z-index: 3;
}
</style>
