export default {
    methods: {
        rulesMinim(minim) {
            return value => (value || '').length >= minim || this.$vuetify.lang.t('$vuetify.rules.minimum-message', minim)
        },
        rulesMax(max) {
            return value => (value || '').length <= max || this.$vuetify.lang.t('$vuetify.rules.maximum-message', max)
        }
    },
    data() {
        return {
            rulesRequired: value => !!value ||  this.$vuetify.lang.t('$vuetify.rules.required-message'),
            rulesEmail: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)  ||  this.$vuetify.lang.t('$vuetify.rules.email-message'),
        }
    }
};
