<template>
  <div class="evaluation-step">
    <div class="evaluation-step__item">
      {{ $vuetify.lang.t('$vuetify.step.evaluation.cleanliness') }}
      <v-rating
          hover
          length="3"
          size="22"
          :value="value.cleanliness"
          color="#8abfdf"
          @input="ratingChange('cleanliness',$event)"
      ></v-rating>
    </div>

    <div  class="evaluation-step__item">
      {{ $vuetify.lang.t('$vuetify.step.evaluation.communication') }}
      <v-rating
          hover
          length="3"
          size="22"
          :value="value.communication"
          color="#8abfdf"
          @input="ratingChange('communication',$event)"
      ></v-rating>
    </div>

    <div  class="evaluation-step__item">
      {{ $vuetify.lang.t('$vuetify.step.evaluation.valueForMoney') }}
      <v-rating
          hover
          length="3"
          size="22"
          :value="value.valueForMoney"
          color="#8abfdf"
          @input="ratingChange('valueForMoney',$event)"
      ></v-rating>
    </div>
  </div>
</template>

<script>
export default {
  name: "EvaluationStep",
  props: ['value'],
  data() {
    return {
      evaluation: {}
    }
  },
  methods: {
    ratingChange(name, event) {
      if (name === 'cleanliness') {
        this.evaluation.cleanliness = event;
      }
      if (name === 'communication') {
        this.evaluation.communication = event;
      }
      if (name === 'valueForMoney') {
        this.evaluation.valueForMoney = event;
      }
      console.log()
      this.$emit('input', this.evaluation)
    }
  },
  mounted() {
    this.evaluation = {...this.value}
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

  .evaluation-step {
    display: flex;
    justify-content: space-around;
    width: 100%;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      flex-direction: column;
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
</style>
