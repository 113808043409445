import { render, staticRenderFns } from "./CardProduct.vue?vue&type=template&id=b53a95aa&scoped=true&"
import script from "./CardProduct.vue?vue&type=script&lang=js&"
export * from "./CardProduct.vue?vue&type=script&lang=js&"
import style0 from "./CardProduct.vue?vue&type=style&index=0&id=b53a95aa&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b53a95aa",
  null
  
)

export default component.exports