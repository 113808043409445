<template>
  <div class="card-product">
    <div class="title">
      {{ $vuetify.lang.t(`$vuetify.home.product.${type}.title`) }}
    </div>
    <div>
      Generator Review
    </div>

    <img :src="require(`@/assets/img/home/product/${logoProduct}.png`)" class="mt-3">
  </div>
</template>

<script>
export default {
  name: "CardProduct",
  props: {
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    logoProduct(){
      switch (this.type){
        case 'airbnb':
          return 'logos_cards_accueil-08'
        case 'hotel':
          return 'logos_cards_accueil-09'
        case 'restaurant':
          return 'logos_cards_accueil-10'
        case 'online-product':
          return 'logos_cards_accueil-11'
        default:
          return null
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.card-product {
  box-shadow: 0 3px 15px 5px rgb(0 0 0 / 58%);
  border-radius: 15px;
  height: 260px;
  width: 180px;
  padding: 10px 10px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: white;


  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 13px;
    height: 220px;
    width: 150px;
  }

  .title {
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
    text-align: center;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: 13px;
    }
  }

  img {
    max-width: 120px;
    max-height: 150px;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      max-width: 80px;
      max-height: 100px
    }
  }
}
</style>
