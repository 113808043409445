<template>
  <div class="content-step">
    <div class="content-step__line content-step__line--top"></div>
    <title-step :step="step" :title="title" class="mb-10"/>
    <div class="content-step__slot">
      <slot></slot>

    </div>
    <div class="content-step__line content-step__line--bottom"></div>
  </div>
</template>

<script>
import TitleStep from "@/components/TitleStep";
export default {
  name: "ContentStep",
  props: {
    title: {
      type: String
    },
    step: {
      type:String
    }
  },
  components: {TitleStep}
}
</script>

<style scoped lang="scss">
@import "@/assets/design/variable.scss";
@import '~vuetify/src/styles/settings/_variables';

.content-step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 410px;
  overflow: auto;


  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    overflow: hidden;
    min-height: 520px;
    padding: 0 35px;
  }


  &__slot {
    flex: 1;
    display: flex;
    width: 100%;
  }

  &__line {
    width: 400px;
    height: 3px;
    background-color: $tertiary;
    border-radius: 99px;
    margin: 20px 0;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      margin-top: 30px;
      margin-bottom: 30px;
      width: 275px;

      &--top {
        margin-top: 50px;
      }

      &--bottom {
        margin-bottom: 2px;
      }

    }
  }
}
</style>
