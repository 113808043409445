<template>
  <v-app-bar
      fixed
      :elevation="$vuetify.breakpoint.smAndDown ? 0 : 10"
      color="white">

    <v-toolbar-title class="ml-10 logo" @click="$router.push('/')">
      Generator Review
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-tabs color="primary" right class="hidden-md-and-down">
      <v-tab to="/airbnb-review">{{$vuetify.lang.t('$vuetify.links.airbnb')}}</v-tab>
      <v-tab to="/contact">{{$vuetify.lang.t('$vuetify.links.contact')}}</v-tab>
      <!--      <v-tab disabled>Se connecter</v-tab>-->
    </v-tabs>
    <!--    <v-btn-->
    <!--        class="hidden-md-and-down mr-2 ml-2"-->
    <!--        outlined-->
    <!--        large-->
    <!--        color="purple"-->
    <!--        disabled-->
    <!--    >-->
    <!--      Créer un compte-->
    <!--    </v-btn>-->
    <SelectLanguage/>
    <v-app-bar-nav-icon @click="$emit('navigation-drawer', true)" aria-label="Menu">
      <v-icon>
        mdi-menu
      </v-icon>
    </v-app-bar-nav-icon>

  </v-app-bar>
</template>

<script>
import SelectLanguage from "@/components/SelectLanguage";

export default {
  name: "HeaderBar",
  data() {
    return {
      collapseOnScroll: true
    }
  },
  components: {SelectLanguage}
}
</script>

<style scoped>
.logo {
  cursor: pointer;
}
</style>
