<template>
  <div class="result-step">
    <p class="font-weight-bold paraf1">
      <span v-if="textGenerated">{{ $vuetify.lang.t('$vuetify.step.result.paraf1') }}</span>
      <span v-else>{{ $vuetify.lang.t('$vuetify.step.result.loading') }}</span>
    </p>


    <div class="flex align-center text-center" v-if="!textGenerated">
      <img src="@/assets/img/loader.gif" alt="image de robot chargement" class="loader-robot">
    </div>

    <section class="review-quote" v-if="textGenerated">
      <blockquote>
        <div class="text-block js-review-placeholder">

          <vue-typed-js :strings="[textGenerated]" :contentType="'html'" :type-speed="10" :showCursor="false" v-if="textGenerated" @onComplete="displayCopyButton= true">
            <p class="typing paragraph_result"></p>
          </vue-typed-js>
        </div>
      </blockquote>


    </section>

    <ButtonWidget class="button-copy" :class="{'button-copy--display': displayCopyButton}" v-if="textGenerated" @click.native="copy">
      <v-icon size="20px" color="white" class="mr-2">
        mdi-content-copy
      </v-icon>
      <div>
        {{ $vuetify.lang.t('$vuetify.step.result.copy') }}
      </div>
      </ButtonWidget>



    <!--    <v-card-->
    <!--        class="elevation-16 mx-auto"-->
    <!--        width="500"-->
    <!--    >-->
    <!--      <v-card-text>-->
    <!--        <vue-typed-js :strings="contentReview()" :type-speed="25"  :showCursor="true">-->
    <!--          <p class="typing"></p>-->
    <!--        </vue-typed-js>-->
    <!--      </v-card-text>-->
    <!--      <v-divider></v-divider>-->
    <!--      <v-card-actions class="justify-space-between">-->
    <!--        <v-btn text>-->
    <!--          No Thanks-->
    <!--        </v-btn>-->
    <!--        <v-btn-->
    <!--            color="primary"-->
    <!--            text-->
    <!--        >-->
    <!--          Rate Now-->
    <!--        </v-btn>-->
    <!--      </v-card-actions>-->
    <!--    </v-card>-->
  </div>
</template>

<script>
import ButtonWidget from "@/components/ButtonWidget";
export default {
  name: "ResultStep",
  data() {
    return {
      displayCopyButton: false
    }
  },
  props: ['textGenerated'],
  components: {ButtonWidget},
  methods: {
    async copy() {
      try {
        await navigator.clipboard.writeText(this.textGenerated);
      } catch($e) {
        alert('Cannot copy');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/design/variable.scss";
@import '~vuetify/src/styles/settings/_variables';

.review-quote {
  display: block;
  background-color: #fff;
  padding: 0px;
  text-align: center;
  position: relative;
  width: 450px;
  min-height: auto;
  margin-top: 20px;
  margin-bottom: 20px;


  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 100%;
  }

}

.review-quote:before {
  top: -1.819rem;
  left: 0.181rem;
}

.review-quote:after {
  line-height: 0;
  bottom: -2.319rem;
  right: 0.181rem;
}

.review-quote::before, .review-quote::after {
  content: "“";
  display: inline-block;
  font-size: 3.5rem;
  line-height: 1;
  color: $primary;
  font-weight: 600;
  position: absolute;
}

.loader-robot {
  width: 400px;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 200px;
  }
}

.result-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .paraf1 {
    margin-bottom: 36px;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      margin-bottom: 16px;
      text-align: center;
    }
  }


  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: 0 10px;
  }


  &__quote {
    text-align: left;
    width: 400px;
    height: 150px;
    border: 1px solid;
  }
}

.paragraph_result {
  text-align: justify;
}

.result-quote {
}

.button-copy {
  display: flex;
  align-content: center;
  margin-top: 13px;
  opacity: 0;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    margin-bottom: 24px;
  }

  &--display {
    animation-name: copyButtonAnimationIn;
    animation-duration: 1s;
    opacity: 1;
  }
}


@keyframes copyButtonAnimationIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
