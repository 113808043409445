<template>
  <button class="button-widget" :disabled="disabled" :class="{'button-widget--disabled': disabled}">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "ButtonWidget",
  props: {
    disabled: {
      type: Boolean
    },
    isLink: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/design/variable.scss";

.button-widget {
  width: fit-content;
  background-color: $secondary;
  color: white;
  border-radius: 10px;
  padding: 5px 20px;
}

button {
  &:disabled {
    background: rgb(0 0 0 / 20%);
  }

  &:hover:not(:disabled) {
    background-color: $primary;
  }
}
</style>