<template>
  <div>
    <div class="welcome-container">
      <WelcomeDescription class="welcome-container__description"/>
      <div class="welcome-container__background" role="img" :aria-label="$vuetify.lang.t('$vuetify.background.alt')" />
    </div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="stepper-box" ref="stepperBox">
            <v-stepper alt-labels v-model="step" class="stepper-radius" :key="keyStepper">
              <v-stepper-items>
                <v-stepper-content step="1">
                  <content-step step="1" :title="$vuetify.lang.t('$vuetify.step.character.title')">
                    <PickCharacterStep v-model="form.character"/>
                  </content-step>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <content-step step="2" :title="$vuetify.lang.t('$vuetify.step.houseType.title')">
                    <PickHouseTypeStep v-model="form.houseType"/>
                  </content-step>
                </v-stepper-content>
                <v-stepper-content step="3">
                  <content-step step="3" :title="$vuetify.lang.t('$vuetify.step.evaluation.title')">
                    <EvaluationStep v-model="form.evaluation"/>
                  </content-step>
                </v-stepper-content>
                <v-stepper-content step="4">
                  <content-step step="4" :title="$vuetify.lang.t('$vuetify.step.precision.title')">
                    <PrecisionStep v-model="form.precision" ref="precision" @onValid="formValidity.precision = $event"/>
                  </content-step>
                </v-stepper-content>
                <v-stepper-content step="5">
                  <content-step step="5" :title="$vuetify.lang.t('$vuetify.step.keyWords.title')">
                    <KeyWordsStep v-model="form.keywords" @onValid="formValidity.keywords = $event"/>
                  </content-step>
                </v-stepper-content>
                <v-stepper-content step="6">
                  <content-step step="6" :title="$vuetify.lang.t('$vuetify.step.result.title')">
                    <ResultStep :textGenerated="textGenerated"/>
                  </content-step>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>

            <div class="stepper-box__action"
                 :class="{'stepper-box__action--space-between': step > 1, 'justify-end': step === 6}">
              <button-widget v-if="step > 1 && step < 6" @click.native="step--">
                {{ $vuetify.lang.t('$vuetify.button.previous') }}
              </button-widget>
              <button-widget v-if="step < 6" @click.native="nextStep()" :disabled="!checkValidity">
                {{ $vuetify.lang.t('$vuetify.button.next') }}
              </button-widget>
              <button-widget v-if="step === 6" @click.native="backToStart">
                {{ $vuetify.lang.t('$vuetify.button.backQuestionOne') }}
              </button-widget>
            </div>
          </div>

          <v-snackbar
              v-model="snackbar"
          >
            Error in the review generation

            <template v-slot:action="{ attrs }">
              <v-btn
                  color="red"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>

        </v-col>

      </v-row>
    </v-container>
  </div>
</template>

<script>
import ButtonWidget from "@/components/ButtonWidget";
import PickCharacterStep from "@/components/PickCharacterStep";
import ContentStep from "@/components/ContentStep";
import PickHouseTypeStep from "@/components/PickHouseTypeStep";
import EvaluationStep from "@/components/EvaluationStep";
import PrecisionStep from "@/components/PrecisionStep";
import KeyWordsStep from "@/components/KeyWordsStep";
import ResultStep from "@/components/ResultStep";
import WelcomeDescription from "@/components/WelcomeDescription";
import animations from "@/mixins/animations";
import GeneratorService from "@/services/generator";
import axiosRecaptcha from "@/mixins/axios-recaptcha";

export default {
  name: 'AirbnbReviewView',
  mixins: [animations, axiosRecaptcha],
  metaInfo: {
    titleTemplate: '%s - Airbnb!',
  },
  components: {
    WelcomeDescription,
    ResultStep,
    KeyWordsStep,
    PrecisionStep,
    EvaluationStep, PickHouseTypeStep, ContentStep, PickCharacterStep, ButtonWidget
  },
  data: () => ({
    keyStepper: 0,
    smAndDown: false,
    snackbar: false,
    textGenerated: "",
    step: 1,
    formValidity: {
      precision: false,
      keywords: false,
    },
    form: {
      language: localStorage.getItem("currentLanguage") || 'fr',
      character: '',
      houseType: '',
      evaluation: {
        cleanliness: -1,
        communication: -1,
        valueForMoney: -1,
      },
      precision: {
        name: "",
        location: "",
        duration: 0,
        personsCount: 0
      },
      keywords: "",
    }
  }),
  destroyed() {
    if (this.smAndDown) {
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  mounted() {
    this.smAndDown = this.$vuetify.breakpoint.smAndDown;
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll);
  },
  computed: {
    checkValidity() {
      if (this.step === 1 && this.form.character) {
        return true
      }
      if (this.step === 2 && this.form.houseType) {
        return true
      }
      if (this.step === 3
          && this.form.evaluation.cleanliness > -1
          && this.form.evaluation.communication > -1
          && this.form.evaluation.valueForMoney > -1
      ) {
        return true
      }
      if (this.step === 4
          && this.formValidity.precision
      ) {
        return true
      }
      if (this.step === 5 && this.formValidity.keywords) {
        return true
      }
      return false
    }
  },
  methods: {
    nextStep() {
      if (this.step === 1 || this.step === 2 || this.step===3) {
        this.step++
      }
      if (this.step === 4 && this.formValidity.precision) {
        this.step++
      }
      if (this.step === 5 && this.formValidity.keywords) {
        this.step++
      }
    },

    async generateReview() {
      this.textGenerated = null;
      try {
        const captchaQuery = await this.axiosReCaptcha('contact')
        const response = await GeneratorService.generatorAirbnb(this.form, captchaQuery);
        this.textGenerated = response?.data?.replace(/(?:\r\n|\r|\n)/g, '<br>');
      } catch (e) {
        console.log(e)
        this.snackbar = true
        this.backToStart();
      }
    },

    backToStart() {
      this.step = 1;
      this.resetForm();
      this.keyStepper++;
    },

    resetForm() {
      this.form = {
        language: 'fr',
        character: '',
        houseType: '',
        evaluation: {
          cleanliness: -1,
          communication: -1,
          valueForMoney: -1,
        },
        precision: {
          name: "",
          location: "",
          duration: 0
        },
        keywords: "",
      }
    }
  },
  watch: {
    step(newValue) {
      if (newValue === 6) {
        this.generateReview();
      }
    }
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.welcome-container__background {
  background-image: url("@/assets/img/image airbnb.svg");
  transform: scale(1);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    background-image: url("@/assets/img/image_airbnb_phone.jpg");
  }
}

</style>
