<template>
  <v-app>
    <NavigationDrawer v-model="drawer"/>

    <HeaderBar @navigation-drawer="drawer = $event"/>
    <v-main>
      <v-fade-transition>
        <router-view />
      </v-fade-transition>
    </v-main>
    <footer-main></footer-main>
  </v-app>
</template>

<script>
import FooterMain from "@/components/FooterMain";
import HeaderBar from "@/components/HeaderBar";
import NavigationDrawer from "@/components/NavigationDrawer";

export default {
  name: 'App',
  title:'Generator Review',
  metaInfo: {
    title: 'Generator Review',
    titleTemplate: '%s - Home!',
    htmlAttrs: {
      lang: localStorage.getItem("currentLanguage"),
      amp: true
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { vmid: 'description', name: 'description', content: "L'application OpenIA basée sur GPT 3 permet de générer automatiquement des avis pour Airbnb." }
    ]
  },
  components: {
    NavigationDrawer,
    HeaderBar,
    FooterMain,
  },

  data: () => ({
    drawer: false
  }),

  created() {
    document.documentElement.lang = localStorage.getItem("currentLanguage");
    //document.title = 'SupeReview'
  }
};
</script>

<style lang="scss" scoped>

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
