import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueI18n from 'vue-i18n'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import i18nfr from '@/assets/i18n/fr.json'
import i18nen from '@/assets/i18n/en.json'
import i18nes from '@/assets/i18n/es.json'

Vue.use(Vuetify);
Vue.use(VueI18n)

const messages = {
    en: {
        $vuetify: i18nen
    },
    fr: {
        $vuetify: i18nfr
    },
    es: {
        $vuetify: i18nes
    },
}

const currentLanguage=  localStorage.getItem('currentLanguage');
if (!currentLanguage) localStorage.setItem('currentLanguage', 'fr');


// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: localStorage.getItem('currentLanguage'), // set locale
    messages, // set locale messages,
    silentTranslationWarn: true
})

export default new Vuetify({
    lang: {
        t: (key, ...params) => i18n.t(key, params),
    },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        themes: {
            light: {
                primary: '#8abfdf',
                secondary: '#9ea43e',
                tertiary: '#9ea43e',
            },
        },
    },
});
