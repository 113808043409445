<template>
  <v-row no-gutters>
    <v-col>
      <avatar-select
        name="cool"
        value="friendly"
        :alt="$vuetify.lang.t('$vuetify.step.character.friendly.alt')"
        :is-selected="value === 'friendly'"
        @change="$emit('input', $event)"
    >{{ $vuetify.lang.t('$vuetify.step.character.friendly.label') }}</avatar-select>
    </v-col>
    <v-col>
    <avatar-select
        name="pro"
        value="professional"
        :alt="$vuetify.lang.t('$vuetify.step.character.professional.alt')"
        :is-selected="value === 'professional'"
        @change="$emit('input', $event)"
    >{{ $vuetify.lang.t('$vuetify.step.character.professional.label') }}
    </avatar-select>
    </v-col>
    <v-col>
    <avatar-select
        name="artiste"
        value="poet"
        :alt="$vuetify.lang.t('$vuetify.step.character.poet.alt')"
        :is-selected="value === 'poet'"
        @change="$emit('input', $event)"
    >{{ $vuetify.lang.t('$vuetify.step.character.poet.label') }}</avatar-select>
    </v-col>
  </v-row>
</template>

<script>
import AvatarSelect from "@/components/AvatarSelect";

export default {
  name: "PickCharacterStep",
  props: ['value'],
  components: {AvatarSelect}
}
</script>

<style scoped lang="scss">
.pick-character {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

</style>
