import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './assets/design/index.scss'
import VueMeta from 'vue-meta'
import * as VueTypedJs from 'vue-typed-js'
import router from './router'
import {VueReCaptcha} from "vue-recaptcha-v3";
import {axiosConfiguration} from "@/config/axios-config";

axiosConfiguration(); // configuraiton des intercepteurs
Vue.use(VueReCaptcha, { siteKey: '6LfcJpwjAAAAAMo9zSJ0929beEMFHeajbNjwou0k' })

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.use(VueTypedJs)

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
