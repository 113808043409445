<template>
  <div class="title-step">
    <div class="title-step--step font-weight-dark"><span>{{ step }}</span></div>
    <div class="title-step--title font-weight-bold "> {{ title }}</div>
  </div>
</template>

<script>
export default {
  props: ['step', 'title'],
  name: "TitleStep"
}
</script>

<style lang="scss" scoped>
@import "@/assets/design/variable.scss";
@import '~vuetify/src/styles/settings/_variables';

.title-step {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  gap: 15px;
  line-height: 30px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    margin-top: 22px;
  }

  &--step {
    height: 35px;
    width: 35px;
    min-height: 35px;
    min-width: 35px;
    line-height: 35px;
    text-align: center;
    background: $secondary;
    border-radius: 50%;
    color: white;

    span {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
    }

  }

  &--title {
    text-transform: uppercase;
    font-size: 20px;
  }
}
</style>
