import Vue from 'vue'
import VueRouter from 'vue-router'
import AirbnbReviewView from "@/views/AirbnbReviewView";
import ContactView from "@/views/ContactView";
import HomeView from "@/views/HomeView";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/airbnb-review',
    name: 'airbnb-review',
    component: AirbnbReviewView,
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
